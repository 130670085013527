<template>
  <div class="main-content">
    <avue-form ref="form" v-model="form" :option="formOption">
      <template slot="imageUploadFormat">
        <el-select
          v-model="form.imageUploadFormat"
          multiple
          placeholder="请选择"
          disabled
        >
          <el-option
            v-for="item in imgList"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </template>
      <template slot="videoUploadFormat">
        <el-select
          v-model="form.videoUploadFormat"
          multiple
          placeholder="请选择"
          disabled
        >
          <el-option
            v-for="item in videoList"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </template>
      <template slot="fileUploadFormat">
        <el-select
          v-model="form.fileUploadFormat"
          multiple
          placeholder="请选择"
          disabled
        >
          <el-option
            v-for="item in fileList"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </template>
      <template slot="menuForm">
        <!-- <el-button @click="handleClose">取消</el-button> -->
        <el-button type="primary" :loading="showLoading" @click="submit(form)"
          >确定</el-button
        >
      </template>
    </avue-form>
  </div>
</template>

<script>
import FormImgUpload from "@/components/formComponents/formImgUpload";
import { systemUploadUpdate, systemUploadView } from "@/api/system/upload";
import { enterChineseEnglish } from "@/utils/formRules";
import { userinfo } from "@/api";
export default {
  name: "createThree",
  components: {
    FormImgUpload,
  },
  data() {
    return {
      imgList: [
        ".png",
        ".jpg",
        ".jpeg",
        ".gif",
        ".svg",
        ".webp",
        ".bmp",
        ".tiff",
      ],
      videoList: [".mp4", ".webm", ".avi", ".mpeg"],
      fileList: [".pdf", ".doc", ".ppt", ".zip", ".rar"],
      form: {
        imageUploadFormat: [],
        imageUploadMaxSize: undefined,
        videoUploadFormat: "",
        videoUploadMaxSize: "",
        fileUploadFormat: "",
        fileUploadMaxSize: "",
      },
      showLoading: false,
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 150,
        column: [
          {
            label: "图片格式",
            prop: "imageUploadFormat",
            type: "text",
            maxlength: 20,
            span: 24,
            placeholder: "请选择",
            rules: [
              {
                required: true,
                message: "请选择图片格式",
                trigger: "blur",
              },
            ],
          },
          {
            label: "图片大小",
            prop: "imageUploadMaxSize",
            type: "number",
            maxRows: 200,
            minRows: 1,
            placeholder: "请输入",
            width: "300",
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入图片大小",
                trigger: "blur",
              },
            ],
          },
          {
            label: "视频格式",
            prop: "videoUploadFormat",
            type: "text",
            maxlength: 20,
            span: 24,
            placeholder: "请选择",
            rules: [
              {
                required: true,
                message: "请选择视频格式",
                trigger: "blur",
              },
            ],
          },

          {
            label: "视频大小",
            prop: "videoUploadMaxSize",
            type: "number",
            maxRows: 200,
            minRows: 1,
            placeholder: "请输入",
            width: "300",
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入视频大小",
                trigger: "blur",
              },
            ],
          },
          {
            label: "文件格式",
            prop: "fileUploadFormat",
            type: "text",
            maxlength: 20,
            span: 24,
            placeholder: "请选择",
            rules: [
              {
                required: true,
                message: "请选择文件格式",
                trigger: "blur",
              },
            ],
          },
          {
            label: "文件大小",
            prop: "fileUploadMaxSize",
            type: "number",
            maxRows: 200,
            minRows: 1,
            placeholder: "请输入",
            width: "300",
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入文件大小",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  created() {
    this.onLoad();
  },
  methods: {
    //初始化
    async onLoad() {
      this.showLoading = true;
      await this.$store.dispatch("fetchUploadView");
      this.form = {
        ...this.$store.state.user.uploadView,
        fileUploadFormat:
          this.$store.state.user.uploadView.fileUploadFormat.split(","),
        imageUploadFormat:
          this.$store.state.user.uploadView.imageUploadFormat.split(","),
        videoUploadFormat:
          this.$store.state.user.uploadView.videoUploadFormat.split(","),
      };
      this.showLoading = false;
    },
    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.form.defaultAvatar = [...e];
      } else {
        this.form.defaultAvatar = [...this.form.defaultAvatar, e];
      }
    },
    //默认设备图
    uploadDefaultDeviceImage(e) {
      if (Array.isArray(e)) {
        this.form.defaultDeviceImage = [...e];
      } else {
        this.form.defaultDeviceImage = [...this.form.defaultDeviceImage, e];
      }
    },
    //默认网关图
    uploadDefaultGatewayImage(e) {
      if (Array.isArray(e)) {
        this.form.defaultGatewayImage = [...e];
      } else {
        this.form.defaultGatewayImage = [...this.form.defaultGatewayImage, e];
      }
    },
    //默认分组图
    uploadDefaultGroupImage(e) {
      if (Array.isArray(e)) {
        this.form.defaultGroupImage = [...e];
      } else {
        this.form.defaultGroupImage = [...this.form.defaultGroupImage, e];
      }
    },
    //默认logo
    uploadDefaultPlatformLogo(e) {
      if (Array.isArray(e)) {
        this.form.platformLogo = [...e];
      } else {
        this.form.platformLogo = [...this.form.platformLogo, e];
      }
    },

    //手机号输入
    salaryDianChange(e, t) {
      const value = e.target.value;
      this.form[t] = value;
    },

    async setForm(form) {
      this.form = form;
    },
    //富文本框变化替换值
    editoChange(e) {
      this.form.details = e;
    },
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          const res = {
            ...form,
            fileUploadFormat: form.fileUploadFormat.join(","),
            imageUploadFormat: form.imageUploadFormat.join(","),
            videoUploadFormat: form.videoUploadFormat.join(","),
          };
          this.showLoading = true;

          systemUploadUpdate({ ...res })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("操作成功");
                this.dialogVisible = false;
                setTimeout(() => {
                  this.resetForm();
                }, 500);
                this.onLoad();
                // this.getUserInfo();
                this.showLoading = false;
              } else {
                // this.$message.error(res.msg);
                this.showLoading = false;
                done();
              }
            })
            .catch(() => {
              this.showLoading = false;
            });
          // done()
        }
      });
    },
    // getUserInfo() {
    //   userinfo()
    //     .then((res) => {
    //       if (res.code === 200) {
    //         this.headPortrait = res.data.headPortrait;
    //         this.fullName = res.data.fullName;
    //         this.$store.commit("setUserInfo", res.data);
    //       }
    //     })
    //     .catch((err) => {
    //       // this.$message.error(err.msg)
    //     });
    // },
  },
};
</script>

<style scoped>
.main-content {
  padding-right: 60%;
}
</style>
